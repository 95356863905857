var baseurl = process.env.NODE_ENV == 'development' ? 'http://localhost:8080/' : 'https://srv.ctedu.com.au/';
export const CONF = {
  axiosBaseUrl: baseurl,
  axiosTimeout: 12000,
  
  promptSingle: 'Please select only one answer',
  promptMore: 'Please select all appropriate answers (one or more)',
  
  clozeStartSS: 15,
  clozeStartOC: 12,
  clozeNums: 6,
}




