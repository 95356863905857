<template>
  <div class="testlist">
    <div id="usernotes" v-show="shownotes">
      <b>ID:</b>{{noteitem.id}}&nbsp;
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==1" style="font-size:12px;color:black;background-color: pink;border:3px pink solid;">CN</span>
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==2" style="font-size:12px;color:white;background-color: blue;border:3px blue solid;">EN</span>
      {{noteitem['student.plain']?'🅿️':''}}
      {{noteitem['student.other']?'🌙':''}}
      &nbsp;<b>R:</b>{{noteitem.created_at?new Date(noteitem.created_at).toLocaleDateString():''}}
      &nbsp;<b>B:</b>{{noteitem.buy_at?new Date(noteitem.buy_at).toLocaleDateString().replace(/01\/01\/1980/,' --'):''}}
      &nbsp;<b>V:</b>{{noteitem.visit_at?new Date(noteitem.visit_at).toLocaleDateString():''}}
      &nbsp;<b>P:{{noteitem.credit}}</b>
      &nbsp;<span @dblclick="chgNote(1)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;"> ♣</span>
      &nbsp;<span @dblclick="chgNote(2)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;">】</span> 
      <br><span style="background-color: #ddd;">{{noteitem.name}}</span>&nbsp;
      {{noteitem.email}}&nbsp;
      <span style="background-color: #ddd;">{{noteitem.mobile}}</span>
      {{noteitem.address}} {{noteitem.suburb}}
      <br><span style="background-color: #ddd;">{{noteitem['student.name']}}</span>
      {{noteitem['student.block']==4?'⛔':noteitem['student.block']==3?'⭕':noteitem['student.block']==2?'🔒🔒':noteitem['student.block']==1?'🔒':''}}
      {{noteitem['student.alert']>2?('❗'+arrNums[noteitem['student.alert']]+'❗'):noteitem['student.alert']==2?'❗❗':noteitem['student.alert']==1?'❗':noteitem['student.alert']==(-1)?'🌵':''}}
      <span @dblclick="mock(noteitem['student.fbuid'])">{{noteitem['student.loginname']}}</span>
      {{Math.floor(noteitem['student.pall']/100%10)>0?' ❰pc'+Math.floor(noteitem['student.pall']/100%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/1000%10)>0?' ❰oc'+Math.floor(noteitem['student.pall']/1000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/10000%10)>0?' ❰ps'+Math.floor(noteitem['student.pall']/10000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/100000%10)>0?' ❰ss'+Math.floor(noteitem['student.pall']/100000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']%100)>0?' ❰rt'+Math.floor(noteitem['student.pall']%100)+'❱':''}}
      &nbsp;&nbsp;<span style="background-color: #ddd;">{{noteitem['student.gender']}}</span>
      &nbsp;&nbsp;<b>Yr</b>{{noteitem['student.grade']}}
      <span v-if="noteitem['student.doidt']==2">⛺</span>
      &nbsp;&nbsp;<b>T:</b>{{new Date(noteitem['student.tested_at']).toLocaleDateString()}}
      &nbsp;&nbsp;<b>⭐{{noteitem['student.score']}} / {{noteitem['student.avg5']}}</b>    
      <a-textarea v-model:value="noteitem.notes" :rows="8" style="margin-bottom:10px;resize: none;" />
      <a-button size="small" type="primary" @click="upNotes(1)" style="margin-right:10px;">Update</a-button>
      <a-button size="small" @click="upNotes(0)" style="margin-right:10px;">Cancel</a-button>
      <span v-if="Object.keys(location).length" style="color: #666;">&nbsp;&nbsp;<b>IP</b>: {{ noteitem.ip }} - {{ location.city }} -  
      {{ location.country_name=='Australia'?location.region_code:location.country_name}} - {{ location.org }}</span> 
    </div>
    <div id="minirpt" v-show="showrpt">
      <b>ID:</b>{{rptitem.id}}&nbsp;&nbsp;
      <b>EN:</b>{{rptitem.scen}} / {{rptitem.pen}}%&nbsp;&nbsp;
      <b>MA:</b>{{rptitem.scma}} / {{rptitem.pma}}%&nbsp;&nbsp;
      <b>TS:</b>{{rptitem.scts}} / {{rptitem.pts}}%
      <br><b>态度:</b>{{rptitem.atustar/10}}⭐（<b>离:</b>{{rptitem.atuleave}}&nbsp;<b>早:</b>{{rptitem.atuearly}}&nbsp;<b>慢:</b>{{rptitem.atuslow}}&nbsp;
      <b>复习:</b>{{rptitem.atuview-10}}%&nbsp;<b>自诊:</b>{{rptitem.atuidt-10}}%）
      <br><b>控时:</b>{{rptitem.tctstar/10}}⭐（<b>空:</b>{{rptitem.tctunans}}&nbsp;<b>慢:</b>{{rptitem.tctslow}}&nbsp;<b>猜:</b>{{rptitem.tctblind}}&nbsp;）
      <br><b>频率:</b>{{rptitem.tfreq}}⭐（{{rptitem.tnumc}}次）&nbsp;&nbsp;<b>平衡:</b>{{rptitem.tbalan}}⭐&nbsp;&nbsp;<b>稳定:</b>{{rptitem.tstab}}⭐&nbsp;&nbsp;
      <b>趋势中:</b>{{rptitem.tgrowth}}&nbsp;&nbsp;<b>长:</b>{{rptitem.tlong}}&nbsp;&nbsp;
      <br><b>Retake:</b>{{rptitem.rtstar/10}}⭐（<b>高:</b>{{rptitem.rtup}}&nbsp;<b>低:</b>{{rptitem.rtunder}}&nbsp;
      <b>数学:</b>{{rptitem.rtma}} / {{rptitem.rtavma}}&nbsp;<b>TS:</b>{{rptitem.rtts}} / {{rptitem.rtavts}}&nbsp;）
      <br><b>阅读:</b>&nbsp;{{rptitem.qnumen}}次（<b>㊀:</b>{{rptitem.wken1}} / {{Math.round(rptitem.wkpen1*100)}}%&nbsp;<b>㊁:</b>{{rptitem.wken2}} / {{Math.round(rptitem.wkpen2*100)}}%&nbsp;<b>㊂:</b>{{rptitem.wken3}} / {{Math.round(rptitem.wkpen3*100)}}%&nbsp;）
      <br><b>数学:</b>&nbsp;{{rptitem.qnumma}}次（<b>㊀:</b>{{rptitem.wkma1}} / {{Math.round(rptitem.wkpma1*100)}}%&nbsp;<b>㊁:</b>{{rptitem.wkma2}} / {{Math.round(rptitem.wkpma2*100)}}%&nbsp;<b>㊂:</b>{{rptitem.wkma3}} / {{Math.round(rptitem.wkpma3*100)}}%&nbsp;）
      <br><b>TS:</b>&nbsp;{{rptitem.qnumts}}次（<b>㊀:</b>{{rptitem.wkts1}} / {{Math.round(rptitem.wkpts1*100)}}%&nbsp;<b>㊁:</b>{{rptitem.wkts2}} / {{Math.round(rptitem.wkpts2*100)}}%&nbsp;<b>㊂:</b>{{rptitem.wkts3}} / {{Math.round(rptitem.wkpts3*100)}}%&nbsp;）
      <br><b>Idt阅读:</b>&nbsp;{{String(rptitem.idtenw).replace('.',' / ')}}次（<b>㊀:</b>{{rptitem.idten1}} / {{Math.round(rptitem.idpen1*100)}}%&nbsp;<b>㊁:</b>{{rptitem.idten2}} / {{Math.round(rptitem.idpen2*100)}}%）
      <br><b>Idt数学:</b>&nbsp;{{String(rptitem.idtmaw).replace('.',' / ')}}次（<b>㊀:</b>{{rptitem.idtma1}} / {{Math.round(rptitem.idpma1*100)}}%&nbsp;<b>㊁:</b>{{rptitem.idtma2}} / {{Math.round(rptitem.idpma2*100)}}%）
      <br><b>IdtTS:</b>&nbsp;{{String(rptitem.idttsw).replace('.',' / ')}}次（<b>㊀:</b>{{rptitem.idtts1}} / {{Math.round(rptitem.idpts1*100)}}%&nbsp;<b>㊁:</b>{{rptitem.idtts2}} / {{Math.round(rptitem.idpts2*100)}}%）
      <br>

      <a-popconfirm placement="top" ok-text="Yes" cancel-text="No" @confirm="miniRpt(rptitem.id,rptitem.test_id)">
        <template v-slot:title>
          <p>确定要删除这条报告吗？</p>
        </template>
        <a-button size="small" style="margin-right:10px;">Del</a-button>
      </a-popconfirm>  
        <a-button type="primary" size="small" @click="miniRpt(0)" style="margin-right:10px;">Close</a-button>
    </div>  
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
    <div class="pageline">
      <a-checkbox style="margin-left:40px;" v-model:checked="check[0]" @change="search(0,1)">SS</a-checkbox>
      <a-checkbox v-model:checked="check[1]" @change="search(0,2)">PS</a-checkbox>
      <a-checkbox v-model:checked="check[2]" @change="search(0,3)">OC</a-checkbox>
      <a-checkbox v-model:checked="check[3]" @change="search(0,4)">PC</a-checkbox>
      <a-checkbox v-model:checked="check[4]" @change="search(0,5)">E</a-checkbox>
      <a-input v-model:value="iptstudent" size="small" style="width: 120px;margin-right:10px;"/>
      <a-button v-if="iptstudent!=''" size="small" type="primary" @click="search(1)" style="margin-right:10px;">Search Student</a-button>
      <a-button v-if="iptstudent" size="small" @click="search(2)">All Students</a-button>
      <a-checkbox style="margin-left:10px;" v-if="iptstudent" v-model:checked="sortsub" @click="search(1)">by subject</a-checkbox>
      <span style="color:#999;">[!@#$]from(-to):days(60):1EN2MA3TS(all)</span>
    </div>    
    <table class="tb88">
      <tr style="text-align:center;font-size:15px;font-weight:bold;">
        <td></td><td>ID</td><td>Start Time</td><td>Duration (Min)</td><td>Status</td><td>Student</td><td>Yr</td><td>Type</td>
        <td>A/T⭐</td><td>Num</td><td>Sub</td><td>Score <u>All</u></td><td>Sub Rank</td><td>All Rank</td>
      </tr>
      <tr :class="item.status==1?'cl_doing':item.status==0?'cl_wait':item.rule.substring(2,4)=='RT'?'cl_retake':'cl_done'" 
          :style="(!singlemode && selectname==item['student.loginname'])?lockname?'background-color:#FFD54F;':'background-color:#FFECB3;':''" 
          v-for="(item,index) in testdoos" :key="'or'+index"
          @click="lockdoo(item['student.loginname'])"
          @mouseover="selectname=lockname?selectname:item['student.loginname']" 
          @mouseleave="selectname=lockname?selectname:''">
        <td>{{(index%5==4 && index<999)?index+1:''}}</td> 
        <td>{{item.id}}</td>    
        <td>
          {{formatSydneyTime(item.started_at)}}
        </td>
        <td>{{Math.round(item.duration/60)}} 
          <span :style="((item.unans/item.quests)>0.5||(item.blur/item.quests)>25||(item.blind/item.quests)>0.5||item.slow>500)?'color:#e33;':'color:#ccb;'">
          -{{item.tok%10000}} | {{item.tmins>0?item.tmins:0}}, U{{item.unans}}, L{{item.blur}}, B{{item.blind}}, S{{item.slow}}</span></td>
        <td>{{item.status==1?'Doing':item.status==2?'Done':item.status==3?'Banned':'Wait'}}</td>
        <td class="td_name">{{item['student.block']==4?'⛔':item['student.block']==3?'⭕':item['student.block']==2?'🔒🔒':item['student.block']==1?'🔒':''}}{{item['student.alert']>2?('❗'+arrNums[item['student.alert']]+'❗'):item['student.alert']==2?'❗❗':item['student.alert']==1?'❗':item['student.alert']==(-1)?'🌵':''}}
        <span @dblclick="showNotes(item['student.loginname'])">{{item['student.loginname']}}</span></td>
        <td>{{item['student.grade']}}</td>
        <td><span @dblclick="item.rpt?miniRpt(item.id):''">{{item.rule.substring(2,4)=='RT'?item.rule.substring(0,4):item.rule.substring(0,2)}}</span>
          <span @dblclick="openRpt(item.id)" v-if="item.rpt">✅</span>
        </td>
        <td>{{item.atustar>0?item.atustar/10+' / ':''}}{{item.tctstar>0?item.tctstar/10:''}}</td>
        <td>{{arrPacks[item.packs]}}{{item.test_num}}</td>
        <td>{{item.levelcode.substring(0,2)}}{{item.levelcode.charAt(item.levelcode.length-1)=='P'?"★":item.levelcode.charAt(item.levelcode.length-1)=='L'?'☆':''}}</td>
        <td>
        <div v-if="singlemode && item.status==2 && item.rule.substring(2,4)!='RT'" class="prog">
          <progress v-if="windowWidth>=600" style="color:green; height:8px; width:100%;margin:-8px 0;" :max="item.quests" :value="item.score"> </progress>
        </div>
        {{item.status==2?(item.score +' / '+ item.quests):''}}
        <b><u>{{(item.status==2 && item.rule.substring(2,4)!='RT' && item.allscore>0)?' '+ item.allscore+'':''}}</u></b>
        </td>
        <td>
        {{item.subrank>0?item.subrank+' / '+item.subatts:''}}{{item.subrank>0?'&nbsp;('+Math.round((item.subatts-item.subrank)*1000/item.subatts)/10+'%)':''}}
        </td>
        <td>
        <div v-if="item.allrank>0" class="prog">
          <progress v-if="windowWidth>=600" style="height:8px; width:100%;margin:-8px 0;" max="100" :value="Math.round((item.allatts-item.allrank)*1000/item.allatts)/10"> </progress>
        </div>
        <b>{{item.allrank>0?'&nbsp;'+Math.round((item.allatts-item.allrank)*1000/item.allatts)/10+'%':''}}</b>
        <b>{{item.allrank>0 && Math.floor((item.allatts-item.allrank)*100/item.allatts)>=90?'🅰':item.allrank>0 && Math.floor((item.allatts-item.allrank)*100/item.allatts)>=70?'🆗':''}}</b>
        {{item.allrank>0?item.allrank+' / '+item.allatts:''}}
        </td>
      </tr>    
    </table>
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
    <div class="pageline">
      <a-checkbox style="margin-left:40px;" v-model:checked="check[0]" @change="search(0,1)">SS</a-checkbox>
      <a-checkbox v-model:checked="check[1]" @change="search(0,2)">PS</a-checkbox>
      <a-checkbox v-model:checked="check[2]" @change="search(0,3)">OC</a-checkbox>
      <a-checkbox v-model:checked="check[3]" @change="search(0,4)">PC</a-checkbox>
      <a-checkbox v-model:checked="check[4]" @change="search(0,5)">E</a-checkbox>
      <a-input v-model:value="iptstudent" size="small" style="width: 120px;margin-right:10px;"/>
      <a-button v-if="iptstudent!=''" size="small" type="primary" @click="search(1)" style="margin-right:10px;">Search Student</a-button>
      <a-button v-if="singlemode" size="small" @click="search(2)">All Students</a-button>
      <a-checkbox style="margin-left:10px;" v-if="iptstudent!=''" v-model:checked="sortsub" @click="search(1)">by subject</a-checkbox>
    </div>  
  </div>
</template>

<script>
import { Geter,Puter } from '@/utils/request.js';
import swal from 'sweetalert2';

export default {
  name:'testlist',
  data () {
    return {
      testdoos: [],
      selectname: '',
      lockname: false,
      totalpages: 1,
      currentpage: 1,
      arrPageNum: [],
      check: [false,false,false,false,false],
      belong: '',
      sortsub: false,
      iptstudent: '',
      singlemode: false,
      arrPacks: ['','►','►►','►►►','►►►►'],
      arrNums: ['','①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮'],
      shownotes: false,
      noteitem: {},
      location: {},
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      showrpt: false,
      rptitem: {},
    };
  },

  components: {},

  computed: {},

  beforeMount() {},

  mounted() {
    this.getTestList();
    window.onresize = () => {
      return (() => {
        let fullWidth = document.documentElement.clientWidth;
        this.windowWidth = fullWidth; 
      })();
    };
  },

  methods: {
    formatSydneyTime(time) {
      const formatter = new Intl.DateTimeFormat('en-AU', {
        weekday: 'short',    
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      return formatter.format(new Date(time));
    },

    async getTestList(topg=0) {
      try {
        this.iptstudent = this.iptstudent.trim();
        let response = await Geter(`testlist`,{pg:topg,bl:this.belong,st:this.iptstudent});
        if (Object.keys(response.data).length && response.data!='Access Denied'){
          this.testdoos = response.data.rows;
          this.totalpages = Math.ceil(response.data.count/200);
          this.totalpages = this.totalpages>999?999:this.totalpages;
          this.arrPageNum = [];
          this.currentpage = 1 + parseInt(response.data.pg);
          for(let i=1;i<=this.totalpages;i++){
            if(Math.abs(this.currentpage-i)<5){
              this.arrPageNum.push(i);
            }else if(Math.abs(this.currentpage-i)<50 && i%10==0){
              this.arrPageNum.push(i);
            }else if(i%100==0){
              this.arrPageNum.push(i);
            }else if(i==1 || i==this.totalpages){
              this.arrPageNum.push(i);
            }
          }
          
          if(this.singlemode && this.sortsub){
            this.testdoos.sort(function(a, b){
              if(a.levelcode.substring(0,2)>b.levelcode.substring(0,2)){
                return(-100);
              }else if(a.levelcode.substring(0,2)<b.levelcode.substring(0,2)){
                return(+100);
              }else{
                return(b.quests * 100 + b.id / 1000000 - (a.quests * 100 + a.id / 1000000));
              }
            });
          }
        }
      }catch(e) {
        console.log(e);
      }
    },

    async mock(stu){
      console.log(stu);
      try {
        let response = await Geter(`mockstudent`,{st:stu});
        if (response.data==201){
          swal.fire({text: 'Successfully imitating the student!',
          timer: 3000,
          showConfirmButton: false});
        }
      }catch(e) {
        console.log(e);
      }
    },

    onChange(topg) {
      this.getTestList(topg);
    },

    lockdoo(stu){
      if(!this.singlemode){
        this.lockname = !this.lockname;
        if(this.lockname){
          this.iptstudent = stu;
        }else{
          this.iptstudent = '';
        }
      }
    },

    openRpt(id){
      let strid = String(id);
      let p = strid.slice(-2);
      let v = strid.slice(0,2);
      let t = strid.slice(2,-2);
      window.open('https://ctedu.com.au/portal?r=1&v='+v+'&t='+t+'&p='+p,'_blank');
    },

    async miniRpt(id,dl=0){
      if(id){
        try {
          let response = await Geter('minirpt',{id:id,dl:dl});
          if(response){
            if(response.data.code==201){
              this.showrpt = true;
              this.rptitem = response.data.item;
            }else if(response.data.code==204){
              console.log('Report Deleted: ',response.data.delnum)
              this.showrpt = false;
              this.rptitem = {};
              this.getTestList(this.currentpage);
            }
          }
        }catch(e) {
          console.log(e);
        }
      }else if(this.showrpt){
        this.showrpt = false;
        this.rptitem = {};
      }
    },

    search(sg=0,bl=0) {
      let arrbl = ['SS','PS','OC','PC','E']
      if(bl>0 && this.check[bl-1]){
        for(let i=0;i<=5;i++){
          if(i!=(bl-1)){
            this.check[i]=false;
          }
        }
        this.belong = arrbl[bl-1];
      }else{
        this.belong = '';
      }
      if(sg==1){
        this.singlemode = true;
        for(let i=0;i<=5;i++){
          if(this.check[i]){
            this.belong = arrbl[i];
          }
        }
      }
      if(sg==2){
        this.singlemode = false;
        this.iptstudent = '';
        for(let i=0;i<=5;i++){
          if(this.check[i]){
            this.belong = arrbl[i];
          }
        }
      }
      this.getTestList();
    },

    async showNotes(loginname){
      if(loginname){
        this.location = {}
        try {
          let response = await Geter('shownote',{ln:loginname});
          if(response && response.data.code==201){
            this.shownotes = true;
            this.noteitem = response.data.item;
            let ipAddress = this.noteitem.visit_ip.split(',')[0];
            this.noteitem.ip = ipAddress.substring(0,20);
            let res = await Geter(`https://ipapi.co/${ipAddress}/json/`)
            this.location = res.data;
          }
        }catch(e) {
          console.log(e);
        }
      }
    },

    async upNotes(type){
      if(type){
        try {
          let response = await Puter('upnote',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
          if(response && response.data==201){
            this.shownotes = false;
            this.noteitem = {};
            this.getTestList(this.currentpage);
          }else{
            swal.fire({text: 'Update failed! To clear please type "Del".',
            timer: 3000,
            showConfirmButton: false});
          }
        }catch(e) {
          console.log(e);
        }
      }else{
        this.shownotes = false;
        this.noteitem = {};
        this.getTestList(this.currentpage);
      }
    },

    chgNote(n) {
      if(n==1){
        this.noteitem.notes = '♣' + this.noteitem.notes;
      }else if(n==2){
        this.noteitem.notes = this.noteitem.notes + '【】';
      }
    },

    async chgLang(id,idx){
      try {
        let response = await Geter('chglang',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
        if(response && response.data==201){
          this.noteitem.lang = 3 - this.noteitem.lang;
          this.users[idx].lang = this.noteitem.lang;
          //this.getTestList(this.currentpage);
        }
      }catch(e) {
        console.log(e);
      }
    },
  },

}

</script>
<style scoped>
.prog{
  position: relative;
  left: 0px;
  top: 0px;
  margin-bottom: -25px;
}

#usernotes{
  position: fixed;
  z-index: 99;
  left: 10%;
  top: 30%;
  width: 580px;
  height: 340px; 
  margin: 8px 8px;
  padding: 16px;
  overflow: none; 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
  text-align: left;
}
#minirpt{
  position: fixed;
  z-index: 99;
  left: 10%;
  top: 30%;
  width: 420px;
  height: 300px; 
  margin: 8px 8px;
  padding: 16px;
  overflow: none; 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
  text-align: left;
}
.testlist{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.pageline{
  margin: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}
.pagetab{
  margin: 0 4px;
  width: 25px;
  height: 20px;
  text-align: center;
  border: 1px solid #999;  
  border-radius: 3px;  
}
.pagetab:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow: 1px 1px 0 1px #666,
              1px 1px 2px 1px #999;
}
.bk_selpg{
  background-color: #FFD54F;
}


.tb88  {
  width: 95%;
  margin: 12px;
  word-break: break-all;
}
.tb88 tr td{
  border: 1px solid black;
}
.cl_doing{
  color: #e33;
}
.cl_done{
  color: #222;
}
.cl_retake{
  color: rgb(79, 168, 182);
}
.cl_wait{
  color: rgb(101, 201, 101);
}
.td_name:hover{
  cursor:pointer;
}

.act_botton {
  font-size: 13px;
  line-height: 18px;
  border-width: 1px;
  border-style: solid;    
  border-color: #666;   
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  margin: 0 4px 0 4px;
  color: white;
  text-shadow: 0 0 1px #000;
  background: #7d9dda;
}
.act_botton:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow:1px 1px 2px #666;
}

@media screen and (max-width: 1000px) {
  *,textarea,button,span {
    font-size: 12px;
  }
  #usernotes{
    left: 1%;
    top: 25%;
    font-size: 10px;
    width: 400px;
    height: 300px; 
    padding: 8px;
  }
}
</style>