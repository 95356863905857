<template>
  <div class="div_column" style="color:red; font-size:30px;">
    {{showrst}}
  </div>

  <div class="div_column">
    Loginname: 
    <a-input v-model:value="octryln" size="small" style="width: 120px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="activeoctry" style="margin-right:10px;">Active OC Try Test</a-button>
  </div>

  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="iptln" size="small" style="width: 120px;margin-right:10px;"/>
    Number: 
    <a-input v-model:value="iptno" size="small" style="width: 50px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="addretake" style="margin-right:10px;">Add Retake</a-button>
  </div>

  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="wrtln" size="small" style="width: 120px;margin-right:10px;"/>
    pkcode: 
    <a-input v-model:value="wrtno" size="small" style="width: 50px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="addwriting" style="margin-right:10px;">Add Writing</a-button>
  </div>

  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="rtkln" size="small" style="width: 120px;margin-right:10px;"/>
    <a-checkbox v-model:checked="checkFnl">Final</a-checkbox>
    <a-button size="small" type="primary" @click="chkretakes" style="margin-right:10px;">Check Retake Suggestions</a-button>
  </div>

  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="datln" size="small" style="width: 120px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="extdate(0)" style="margin-right:10px;">Check Pack Expiry Date</a-button>
    <div v-if="currentdate">
      New Expiry Date: 
      <a-input v-model:value="datno" size="small" style="width: 120px;margin-right:10px;"/>00:00:00&nbsp;&nbsp;
      <a-button size="small" type="primary" @click="extdate(1)" style="margin-right:10px;">Set New Exp Date</a-button>
    </div>
  </div>
  
  <hr>
  <div class="div_column">
    Test ID: 
    <a-input v-model:value="testid" size="small" style="width: 120px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="cleartest" style="margin-right:10px;">Fix Unfinished Test</a-button>
  </div>

  <hr>
  <div class="div_column">
    Test ID:(last set only) 
    <a-input v-model:value="testid2" size="small" style="width: 120px;margin-right:10px;"/>
    Sub Rank: 
    <a-input v-model:value="testrank" size="small" style="width: 50px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="resettest" style="margin-right:10px;">Reset Test</a-button>
    <span style="color:#999;">9999=0;</span>
  </div>


  <hr>
  <div class="div_column">
    Writing ID:
    <a-input v-model:value="writingid" size="small" style="width: 100px;margin-right:10px;"/>
    Writing Score:
    <a-input v-model:value="writingscore" size="small" style="width: 50px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="resetwriting" style="margin-right:10px;">Reset Writing</a-button>
    <span style="color:#999;">9999=0;</span>
  </div>


  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="blockln" size="small" style="width: 120px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="blockuser(1)" style="margin-right:10px;">1 - Block Buy</a-button>
    <a-button size="small" type="primary" @click="blockuser(2)" style="margin-right:10px;">2 - Block RT Buy</a-button>
    <a-button size="small" type="primary" @click="blockuser(4)" style="margin-right:10px;">4 - Block Test & All</a-button>
    <a-button size="small" @click="blockuser(0)" style="margin-right:10px;">0 - Recover</a-button>
  </div>

  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="alertln" size="small" style="width: 120px;margin-right:10px;"/>
    Number: 
    <a-input v-model:value="alertno" size="small" style="width: 50px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="setalert" style="margin-right:10px;">Set Alert</a-button>
    <span style="color:#999;">99=-1;100=0;</span>
  </div>

  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="ignoreln" size="small" style="width: 120px;margin-right:10px;"/>
    Number: 
    <a-input v-model:value="ignoreno" size="small" style="width: 50px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="setignore" style="margin-right:10px;">Set ignore Time</a-button>
    <span style="color:#999;">ignore=1;normal=0;</span>
  </div>

  <hr>
  <div class="div_column" style="color:red; font-size:30px;">
    {{showrst}}
  </div>
  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="mailln" size="small" style="width: 120px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="sendmail('SS')" style="margin-right:10px;">Send Mail SS</a-button>
    <a-button size="small" type="primary" @click="sendmail('OC')" style="margin-right:10px;">Send Mail OC</a-button>
  </div>


  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="suspln" size="small" style="width: 120px;margin-right:10px;"/>
    Score:
    <a-input v-model:value="suspsc" size="small" style="width: 60px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="suspendtest(1)" style="margin-right:10px;">Sus UnFi Test</a-button>
    <a-button size="small" type="primary" @click="suspendtest(2)" style="margin-right:10px;">Sus UnFi T+Re</a-button>
    <a-button size="small" type="primary" @click="suspendtest(9)" style="margin-right:10px;">Sus All Test</a-button>
  </div>

  <hr>
  <div class="div_column">
    User ID: 
    <a-input v-model:value="userci" size="small" style="width: 80px;margin-right:10px;"/>
    Current Credit:
    <a-input v-model:value="usercc" size="small" style="width: 60px;margin-right:10px;"/>
    New Credit:
    <a-input v-model:value="usernc" size="small" style="width: 60px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="setcredit" style="margin-right:10px;">Set Credit</a-button>
  </div>

  <hr>
  <div class="div_column">
    Order Num: 
    <a-input v-model:value="ordernum" size="small" style="width: 120px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="xpromo" style="margin-right:10px;">XX Promo</a-button>
  </div>

  <hr>
  <div class="div_column">
    Loginname: 
    <a-input v-model:value="clonefrln" size="small" style="width: 120px;margin-right:10px;"/>
    Clone to: 
    <a-input v-model:value="clonetoln" size="small" style="width: 120px;margin-right:10px;"/>
    <a-button size="small" type="primary" @click="setclone" style="margin-right:10px;">Set Clone</a-button>
  </div>


  <hr>
  <div class="div_col2">
    <div class="div_column">
      Search string:
      <a-input v-model:value="seek1" size="small" style="width: 400px;margin-right:10px;"/>
      <a-input v-if="advReplace" v-model:value="seek2" size="small" style="width: 400px;margin-right:10px;"/>
    </div>
    <div class="div_column">
      Replace string:
      <a-input v-model:value="replace1" size="small" style="width: 400px;margin-right:10px;"/>
      <a-input v-if="advReplace" v-model:value="replace2" size="small" style="width: 400px;margin-right:10px;"/>
    </div>
    <div class="div_column">
      <a-checkbox v-model:checked="advReplace">Adv |</a-checkbox>
      <a-checkbox v-model:checked="searchOnly">Search Only |</a-checkbox>
      <span v-if="advReplace">Adj@@@:</span>
      <a-input  v-if="advReplace" v-model:value="adjuststr" size="small" style="width: 200px;margin-right:10px;"/>
      Quest ID:
      <a-input v-model:value="questidr1" size="small" style="width: 60px;margin-right:10px;"/>
      <a-button size="small" type="primary" @click="replacequest(1)" style="margin-right:10px;">Content</a-button>
      <a-button size="small" type="primary" @click="replacequest(2)" style="margin-right:10px;">Solution</a-button>
      <a-button size="small" type="primary" @click="replacequest(3)" style="margin-right:10px;">Act_str</a-button>
    </div>
  </div>




</template>


<script>
import { Geter } from '@/utils/request.js';

export default {
  name:'testlist',
  data () {
    return {
      iptpass: '',
      iptsubuid:'',
      iptalluid:'',
      show001: false,
      octryln: '',
      iptln: '',
      iptno: '',
      wrtln: '',
      wrtno: '',
      showrst: '',
      blockln: '',
      alertln: '',
      alertno: '',
      ignoreln: '',
      ignoreno: '',
      mailln: '',
      ordernum: '',
      testid: '',
      testid2: '',
      testrank: '',
      writingid: '',
      writingscore: '',
      datln: '',
      datno: '',
      currentdate: '',
      rtkln: '',
      checkFnl: false,
      suspln: '',
      suspsc: '',
      userci: '',
      usercc: '',
      usernc: '',
      clonefrln: '',
      clonetoln: '',
      questidr1: '',
      seek1: '',
      seek2: '',
      replace1: '',
      replace2: '',
      adjuststr: '',
      advReplace: false,
      searchOnly: true,
      tnum: '',
    };
  },

  components: {},

  computed: {},

  beforeMount() {},

  mounted() {

  },

  methods: {
    async activeoctry(){
      if(this.octryln!=''){
        this.showrst = '';
        try {
          let response = await Geter(`activeoctry`,{ln:this.octryln.trim()});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.octryln = '';
      }
    },
    
    async addretake(){
      if(this.iptln!='' && this.iptno>0){
        this.showrst = '';
        try {
          let response = await Geter(`addretake`,{ln:this.iptln.trim(),no:this.iptno});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.iptno = '';
      }
    },

    async addwriting(){
      if(this.wrtln!='' && this.wrtno>0){
        this.showrst = '';
        try {
          let response = await Geter(`addwriting`,{ln:this.wrtln.trim(),no:this.wrtno});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.wrtno = '';
      }
    },

    async chkretakes(){
      if(this.rtkln){
        this.showrst = '';
        try {
          let fnl = this.checkFnl?1:0;
          let response = await Geter(`chkretakes`,{ln:this.rtkln.trim(),fnl:fnl});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.rtkln = '';
      }
    },

    async blockuser(type){
      if(this.blockln!=''){
        this.showrst = '';
        try {
          let response = await Geter(`blockuser`,{ln:this.blockln.trim(),at:type});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.blockln = '';
      }
    },

    async setalert(){
      if(this.alertln!='' && this.alertno>0){
        this.showrst = '';
        try {
          let response = await Geter(`setalert`,{ln:this.alertln.trim(),no:this.alertno});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.alertln = '';
        this.alertno = '';
      }
    },

    async setignore(){
      if(this.ignoreln!='' && this.ignoreno!=''){
        this.showrst = '';
        try {
          let response = await Geter(`setignore`,{ln:this.ignoreln.trim(),no:this.ignoreno});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.ignoreln = '';
        this.ignoreno = '';
      }
    },

    async sendmail(type){
      if(this.mailln!=''){
        this.showrst = '';
        try {
          let response = await Geter(`sendmail`,{ln:this.mailln.trim(),tp:type});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.mailln = '';
      }
    },
    async setclone(){
      if(this.clonefrln!='' && this.clonetoln!=''){
        this.showrst = '';
        try {
          let response = await Geter(`setclone`,{ln:this.clonefrln.trim(),to:this.clonetoln.trim()});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.clonefrln = '';
        this.clonetoln = '';
      }
    },

    async suspendtest(typ=1){
      if(this.suspln && this.suspsc){
        try {
          let response = await Geter(`suspendtest`,{ln:this.suspln.trim(),sc:this.suspsc,tp:typ});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.suspln = '';
        this.suspsc = '';
      }
    },

    async setcredit(){
      if(this.userci && this.usercc && this.usernc){
        try {
          let response = await Geter(`setpoints`,{ui:this.userci,cc:this.usercc,nc:this.usernc});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.userci = '';
        this.usercc = '';
        this.usernc = '';
      }
    },

    async xpromo(){
      if(this.ordernum!=''){
        this.showrst = '';
        try {
          let response = await Geter(`xpromo`,{od:this.ordernum});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.ordernum = '';
      }
    },

    async cleartest(){
      if(this.testid!=''){
        this.showrst = '';
        try {
          let response = await Geter(`cleartest`,{td:this.testid});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.testid = '';
      }
    },

    async resettest(){
      if(this.testid2!='' && this.testrank!=''){
        this.showrst = '';
        try {
          let response = await Geter(`resettest`,{td:this.testid2,rk:this.testrank});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.testid = '';
      }
    },

    async resetwriting(){
      if(this.writingid!='' && this.writingscore!=''){
        this.showrst = '';
        try {
          let response = await Geter(`resetwriting`,{td:this.writingid,sc:this.writingscore});
          if (Object.keys(response.data).length){
            this.showrst = response.data.msg;
          }
        }catch(e) {
          console.log(e);
        }
        this.writingscore = '';
      }
    },

    async extdate(type){
      if(this.datln!=''){
        if(type!=1){
          this.showrst = '';
          try {
            let response = await Geter(`extendtest`,{ln:this.datln.trim()});
            if (Object.keys(response.data).length){
              this.currentdate = response.data.code;
              this.datno = response.data.code;
              this.showrst = response.data.msg;
            }
          }catch(e) {
            console.log(e);
          }
        }else{
          this.showrst = '';
          try {
            let response = await Geter(`extendtest`,{ln:this.datln,dt:this.datno});
            if (Object.keys(response.data).length){
              this.showrst = response.data.msg;
            }
          }catch(e) {
            console.log(e);
          }
          this.datno = '';
          this.currentdate = '';
        }
      }
    },

    async replacequest(type) {
      this.showrst = '';
      try {
        let response = await Geter(`replacequest`,{adv:this.advReplace,src:this.searchOnly,tp:type,id:this.questidr1,s1:this.seek1,s2:this.seek2,r1:this.replace1,r2:this.replace2,ra:this.adjuststr});
        if (Object.keys(response.data).length){
          this.showrst = response.data.msg;
        }
      }catch(e) {
        console.log(e);
      }
    },


  }
}

</script>
<style scoped>
.system{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.div_column{
  margin: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  line-height: 36px;
}

.div_col2{
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pagetab{
  margin: 0 4px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 1px solid #999;  
  border-radius: 3px;  
}
.pagetab:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow: 1px 1px 0 1px #666,
              1px 1px 2px 1px #999;
}
.bk_selpg{
  background-color: #FFD54F;
}


.tb88  {
  width: 95%;
  margin: 24px;
}
.tb88 tr td{
  border: 1px solid black;
}
.cl_doing{
  color: rgb(255, 0, 0);
}
.cl_done{
  color: #333;
}
.td_name:hover{
  cursor:pointer;
}

.act_botton {
  font-size: 13px;
  line-height: 18px;
  border-width: 1px;
  border-style: solid;    
  border-color: #666;   
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  margin: 0 4px 0 4px;
  color: white;
  text-shadow: 0 0 1px #000;
  background: #7d9dda;
}
.act_botton:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow:1px 1px 2px #666;
}
</style>